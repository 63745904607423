import React from "react"
import { Provider } from "react-redux"
import Layout from "../components/Layout"

class APIExplorerPage extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      Playground: null,
      store: null,
    }
  }
  componentDidMount() {
    import("graphql-playground-react")
      .then(({ Playground, store }) => {
        this.setState({ Playground: Playground, store: store })
      })
      .catch(error => console.error("error", error))
  }
  render() {
    if (this.state.store && this.state.Playground) {
      return (
        <Layout>
          <div className="api-playground">
            <Provider store={this.state.store}>
              <this.state.Playground endpoint={`${process.env.API_URL}`} />
            </Provider>
          </div>
        </Layout>
      )
    } else {
      return null
    }
  }
}

export default APIExplorerPage
